import "bootstrap";
import objectFitImages from 'object-fit-images';
import './jquery.rwdImageMaps.min.js';

window.jQuery = $;
$(function () {

    // bs dropdown
    // jump to data-href when the dropdown is open
    $('.dropdown-toggle').click(function (e) {
        const dropdown = $(this).parents('.dropdown');
        const isOpen = dropdown.hasClass('show');
        const href = $(this).data('href');
        if (isOpen && href) {
            window.location.href = href;
            e.stopPropagation();
            return false;
        }
    });

    // ofi
    objectFitImages();

    // jquery.rwdImageMaps.min.js for responsive clickable maps
    $('img[usemap]').rwdImageMaps();

    // floating "back to top" button
    $('#scroll-top').hide()
        .click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 300);
            return false;
        });
    $(window).scroll(function () {
        const topBtn = $('#scroll-top');
        const nav = $("#fix-nav")
        let pos = $(this).scrollTop();
        if (pos > 400) {
            nav.fadeIn('slow');
            topBtn.fadeIn();
        } else {
            topBtn.fadeOut();
            nav.fadeOut('fast');
        }
    });

    // search
    $('#site-search').submit(function (e) {
        if ($('#s').val() === '') {
            e.preventDefault();
        }
    });

    // select for year and month for archives
    const selectJump = $('#archive-selector-to-jump');
    if (selectJump.length) {
        selectJump.change(function () {
            window.location = $(this).val();
        });
    }

    // anchor link width adjusted position for fix nav
    $('a[href^="#"]').click(function () {
        const headerHeight = $('#fix-nav').outerHeight() + 20;
        let href = $(this).attr("href");
        href = ('#' === href) ? '#pagetop' : href;
        const position = $(href).offset().top - headerHeight;
        $('body,html').stop().animate({
            scrollTop: position}, 500);
    });

})